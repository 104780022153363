import React, { useEffect, useState } from "react";
import {
  Container,
  FormContainer,
  FormHeader,
} from "../../Components/styles-all";

import supabase from "../../Supabase";
import styled from "styled-components";
import HeaderGeral from "../../Components/headerGeral";
import ButtonReturn from "../../Components/button-return";
import { useNavigate } from "react-router-dom";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }

  p {
    margin: 8px 0;
    font-size: 1rem;
    color: #111;
    text-align: justify;
  }
`;

export default function Sugestoes() {
  const [respostas, setRespostas] = useState([]);

  useEffect(() => {
    const fetchRespostas = async () => {
      try {
        const { data, error } = await supabase
          .from("pesquisa_satisfacao")
          .select("*");

        if (error) throw error;

        setRespostas(data);
      } catch (error) {
        console.error("Erro ao buscar dados: ", error.message);
      }
    };

    fetchRespostas();
  }, []);

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>RESULTADOS DA PESQUISA DE SATISFAÇÃO</h2>
          </FormHeader>
          <ButtonReturn onClick={handleProfileClick} />

          <Grid>
            {respostas.map((resposta, index) => (
              <Card key={index}>
                <h3>Satisfação: {resposta.satisfacao}</h3>
                {resposta.positivo && (
                  <p>
                    <strong>Positivo:</strong> {resposta.positivo}
                  </p>
                )}

                {resposta.sugestoes && (
                  <p>
                    <strong>Sugestões:</strong> {resposta.sugestoes}
                  </p>
                )}
              </Card>
            ))}
          </Grid>
        </FormContainer>
      </Container>
    </>
  );
}
