import React, { useEffect, useState } from "react";
import supabase from "../../../../Supabase";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  FormContainer,
  ChartContainer,
  ResultTitle,
  ToggleButton,
} from "./styles";

import { Container } from "../../../../Components/styles-all";

import {
  questionsAndResponses,
  questionsAndResponsesFormBIA,
  questionsAndResponsesFormBIATitles,
} from "./titles";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

const chartOptions = {
  indexAxis: "y",
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          const totalResponses = dataset.totalResponses || 0;
          const count = Math.round((value / 100) * totalResponses);
          return `Quantidade: ${count} (${value.toFixed(2)}%)`;
        },
      },
    },
    datalabels: {
      color: "#111",
      display: function (context) {
        return context.dataset.data[context.dataIndex] !== 0;
      },
      font: {
        weight: "bold",
      },
      formatter: function (value, context) {
        return `${value.toFixed(2).toLocaleString("pt-BR")}%`;
      },
      padding: 10,
    },
  },
  scales: {
    y: {
      ticks: {
        padding: 20,
      },
    },
    x: {
      ticks: {
        beginAtZero: true,
        stepSize: 20,
        callback: function (value) {
          return `${value}%`;
        },
      },
    },
  },
};

const thresholds = {
  gorduracorporal: { baixo: 20, bom: 30, alto: 40 },
  imc: { baixo: 18.5, bom: 24.9, alto: 30 },
  gorduravisceral: { baixo: 5, bom: 10, alto: 15 },
  massamuscular: { baixo: 30, bom: 40, alto: 50 },
  massaossea: { baixo: 2, bom: 3, alto: 4 },
  aguacorporal: { baixo: 20, bom: 30, alto: 40 },
};

function getResponseValue(value, thresholds) {
  if (value < thresholds.baixo) return "Baixo";
  if (value <= thresholds.bom) return "Bom";
  return "Alto";
}

const ResultadoBIA = ({ escola }) => {
  console.log(escola);

  const [showAll3, setShowAll3] = useState(false);
  const [resultsForm3, setResultsForm3] = useState([]);
  const [resultsForm21, setResultsForm21] = useState([]);
  const [activeChart, setActiveChart] = useState(null);
  useEffect(() => {
    async function fetchData() {
      let form2DataQuery = supabase.from("sociodemograficoEscolar").select("*");

      if (escola) {
        form2DataQuery = form2DataQuery.eq("escola", escola);
      }

      const { data: form2Data, error: form2Error } = await form2DataQuery;

      if (form2Error) {
        console.error("Erro ao buscar dados do Form2:", form2Error);
        return;
      }

     
      const { data: form3Data, error: form3Error } = await supabase
        .from("habitosVida")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        ); 

      const { data: form21Data, error: form21Error } = await supabase
        .from("bioimpedancia")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );

      if (form3Error) {
        console.error("Erro ao buscar dados do Form3:", form3Error);
      } else {
        setResultsForm3(form3Data);
      }

      if (form21Error) {
        console.error("Erro ao buscar dados do Form21:", form21Error);
      } else {
        setResultsForm21(form21Data);
      }
    }

    fetchData();
  }, [escola]);

  const colors = [
    "rgba(255, 182, 193, 1)",
    "rgba(173, 216, 230, 1)",
    "rgba(255, 255, 179, 1)",
    "rgba(144, 238, 144, 1)",
    "rgba(216, 191, 216, 1)",
    "rgba(176, 224, 230, 1)",
    "rgba(255, 228, 181, 1)",
    "rgba(144, 238, 144, 1)",
    "rgba(255, 160, 122, 1)",
    "rgba(221, 160, 221, 1)",
    "rgba(216, 191, 216, 1)",
    "rgba(152, 251, 152, 1)",
    "rgba(176, 224, 230, 1)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 255, 127, 1)",
    "rgba(102, 205, 170, 1)",
    "rgba(255, 222, 173, 1)",
    "rgba(189, 183, 107, 1)",
  ];

  const responseCounts = {};

  for (let question in questionsAndResponsesFormBIA) {
    responseCounts[question] = Array(
      questionsAndResponsesFormBIA[question].length
    ).fill(0);
  }

  resultsForm21.forEach((entry) => {
    for (let question in questionsAndResponsesFormBIA) {
      const entryValue = parseFloat(entry[question]?.trim()).toFixed(1);

      const response = getResponseValue(
        parseFloat(entryValue),
        thresholds[question]
      );

      const responseIndex =
        questionsAndResponsesFormBIA[question].indexOf(response);
      if (responseIndex !== -1) {
        responseCounts[question][responseIndex]++;
      } else {
      }
    }
  });

  const responsePercentages = {};
  for (let key in responseCounts) {
    const totalResponses = resultsForm21.length;
    responsePercentages[key] = responseCounts[key].map((count) => {
      const percentage = (count / totalResponses) * 100;
      return Math.min(100, Math.max(0, percentage));
    });
  }

  return (
    <Container>
      <ResultTitle>bioimpedância</ResultTitle>

      <FormContainer>
        {Object.keys(questionsAndResponsesFormBIA).map((question, index) => (
          <div key={index}>
            <ToggleButton
              onClick={() => {
                if (activeChart === question) {
                  setActiveChart(null);
                } else {
                  setActiveChart(question);
                }
              }}
            >
              {activeChart === question
                ? `Esconder ${questionsAndResponsesFormBIATitles[question]}`
                : `Mostrar ${questionsAndResponsesFormBIATitles[question]}`}
            </ToggleButton>

            {activeChart === question && (
              <ChartContainer>
                <ResultTitle>
                  {questionsAndResponsesFormBIATitles[question]}
                </ResultTitle>
                <Bar
                  data={{
                    labels: questionsAndResponsesFormBIA[question],
                    datasets: [
                      {
                        label: "ocultar",
                        data: responsePercentages[question],
                        backgroundColor: colors,
                        borderColor: colors.map((color) =>
                          color.replace("0.5", "1")
                        ),
                        borderWidth: 1,
                        totalResponses: resultsForm21.length,
                      },
                    ],
                  }}
                  options={chartOptions}
                />
              </ChartContainer>
            )}
          </div>
        ))}
      </FormContainer>

      <ToggleButton onClick={() => setShowAll3(!showAll3)}>
        {showAll3 ? "Esconder todos" : "Mostrar todos"}
      </ToggleButton>

      <FormContainer>
        {showAll3 &&
          Object.keys(questionsAndResponsesFormBIA).map((question, index) => (
            <div key={index}>
              <ChartContainer>
                <ResultTitle>
                  {questionsAndResponsesFormBIATitles[question]}
                </ResultTitle>
                <Bar
                  data={{
                    labels: questionsAndResponsesFormBIA[question],
                    datasets: [
                      {
                        label: "ocultar",
                        data: responsePercentages[question],
                        backgroundColor: colors,
                        borderColor: colors.map((color) =>
                          color.replace("0.5", "1")
                        ),
                        borderWidth: 1,
                        totalResponses: resultsForm21.length,
                      },
                    ],
                  }}
                  options={chartOptions}
                />
              </ChartContainer>
            </div>
          ))}
      </FormContainer>
    </Container>
  );
};

export default ResultadoBIA;
