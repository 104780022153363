import React, { useState } from "react";
import {
  Container,
  FormContainer,
  FormHeader,
  SummaryInput,
} from "../../Components/styles-all";

import FormGroup from "../../Components/form-group";
import FormRadio from "../../Components/form-radio";
import FormRadioGroup from "../../Components/form-radio-group";

import ButtonSend from "../../Components/button-send";

import supabase from "../../Supabase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderPesquisa from "../../Components/headerPesquisa";

import styled from "styled-components";

const FormLabel = styled.label`
  margin-bottom: 10px;
  display: flex;
  text-align: justify;
  font-size: 1rem;
`;

export default function PesquisaSatisfacao() {
  const [respostas, setRespostas] = useState({
    satisfacao: "",
    positivo: "",
    sugestoes: "",
  });

  const handleChange = (field, value) => {
    setRespostas((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      const { data, error } = await supabase
        .from("pesquisa_satisfacao")
        .insert([respostas]);

      if (error) throw error;

      // Exibe um toast de sucesso
      toast.success("Pesquisa enviada com sucesso!");
    } catch (error) {
      // Exibe um toast de erro
      toast.error("Erro ao enviar a pesquisa: " + error.message);
    }
  };

  return (
    <>
      <HeaderPesquisa />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>AVALIAÇÃO DO PROJETO SAÚDE NA ESCOLA</h2>
          </FormHeader>

          <FormGroup>
            <FormLabel>
              Prezado(a) colaborador(a),
              <br />
              <br />
              Estamos realizando uma pesquisa sobre sua percepção da avaliação
              relacionada aos aspectos de saúde e qualidade de vida dos
              funcionários da sua escola. Seu retorno é essencial para
              aprimorarmos o processo e garantir um ambiente de trabalho mais
              saudável e acolhedor. A sua participação é voluntária e as
              respostas serão tratadas com total confidencialidade. Agradecemos
              sua colaboração e contribuição para o nosso contínuo
              aprimoramento.
              <br />
              <br />
              Atenciosamente,
              <br />
              <br />
              Inlearning Pesquisa e Desenvolvimento
            </FormLabel>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Em uma escala de 1 a 5, quão satisfeito(a) você está com o
              processo de avaliação relacionada aos aspectos de saúde e
              qualidade de vida dos funcionários da sua escola. realizado na
              escola, considerando a clareza das informações fornecidas, o apoio
              recebido e o impacto percebido no seu bem-estar?
            </FormLabel>

            <FormRadioGroup
              value={respostas.satisfacao}
              onChange={(e) => handleChange("satisfacao", e.target.value)}
            >
              {[
                { value: "insatisfeito", label: "Insatisfeito" },
                { value: "pouco satisfeito", label: "Pouco satisfeito" },
                { value: "satisfeito", label: "Satisfeito" },
                { value: "muito satisfeito", label: "Muito satisfeito" },
              ].map(({ value, label }) => (
                <FormRadio
                  key={value}
                  name="satisfacao"
                  value={value}
                  checked={respostas.satisfacao === value}
                  onChange={(e) => handleChange("satisfacao", e.target.value)}
                  label={label}
                />
              ))}
            </FormRadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel>
              O que você destacaria de positivo no processo de avaliação
              relacionada aos aspectos de saúde e qualidade de vida dos
              funcionários da sua escola.?
            </FormLabel>
            <SummaryInput
              value={respostas.positivo}
              onChange={(e) => handleChange("positivo", e.target.value)}
              placeholder="Digite sua resposta aqui..."
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Quais sugestões você daria para melhorar a abordagem da avaliação
              relacionada aos aspectos de saúde e qualidade de vida dos
              funcionários da sua escola.?
            </FormLabel>
            <SummaryInput
              value={respostas.sugestoes}
              onChange={(e) => handleChange("sugestoes", e.target.value)}
              placeholder="Digite sua resposta aqui..."
            />
          </FormGroup>

          <ButtonSend onClick={handleSubmit} />
        </FormContainer>
      </Container>

      <ToastContainer />
    </>
  );
}
