import React, { useEffect, useState } from "react";
import HeaderGeral from "../../../Components/headerGeral";
import {
  Container,
  FormContainer,
  FormHeader,
  Table,
  TableTD,
  TableTH,
} from "../../../Components/styles-all";
import supabase from "../../../Supabase";

import FormGroup from "../../../Components/form-group";
import FormLabel from "../../../Components/form-label";
import FormSelect from "../../../Components/form-select";
import { useNavigate } from "react-router-dom";
import ButtonReturn from "../../../Components/button-return";
import { DivButton } from "../lista-de-resultados/resultadoCivil/styles";
import styled from "styled-components";

const ScrollableContainer = styled.div`
  max-width: 95vw;
  overflow-x: auto;
  margin: 0 auto; 
`;

const avaliarBioimpedancia = (bioimpedancia, gender, ageRange) => {
  const referencias = {
    Feminino: {
      "20-30": {
        gorduraCorporal: [18, 28],
        imc: [18.5, 24.9],
        gorduraVisceral: [0, 9],
        massaMuscular: [30, 40],
        massaOssea: [2, 3.5],
        aguaCorporal: [45, 60],
      },
      "31-40": {
        gorduraCorporal: [20, 30],
        imc: [18.5, 24.9],
        gorduraVisceral: [0, 9],
        massaMuscular: [28, 38],
        massaOssea: [2, 3.5],
        aguaCorporal: [45, 60],
      },
      "41-50": {
        gorduraCorporal: [22, 32],
        imc: [18.5, 24.9],
        gorduraVisceral: [0, 9],
        massaMuscular: [26, 36],
        massaOssea: [2, 3.5],
        aguaCorporal: [45, 60],
      },
    },
    Masculino: {
      "20-30": {
        gorduraCorporal: [10, 20],
        imc: [18.5, 24.9],
        gorduraVisceral: [0, 9],
        massaMuscular: [40, 50],
        massaOssea: [2.5, 4],
        aguaCorporal: [50, 65],
      },
      "31-40": {
        gorduraCorporal: [12, 22],
        imc: [18.5, 24.9],
        gorduraVisceral: [0, 9],
        massaMuscular: [38, 48],
        massaOssea: [2.5, 4],
        aguaCorporal: [50, 65],
      },
      "41-50": {
        gorduraCorporal: [14, 24],
        imc: [18.5, 24.9],
        gorduraVisceral: [0, 9],
        massaMuscular: [36, 46],
        massaOssea: [2.5, 4],
        aguaCorporal: [50, 65],
      },
      "acima-50": {
        gorduraCorporal: [16, 26],
        imc: [18.5, 24.9],
        gorduraVisceral: [0, 9],
        massaMuscular: [34, 44],
        massaOssea: [2.5, 4],
        aguaCorporal: [50, 65],
      },
    },
  };

  const faixa = referencias[gender]?.[ageRange];
  if (!faixa) return {};

  const avaliar = (valor, [min, max]) => {
    if (valor < min) return "Baixo";
    if (valor > max) return "Alto";
    return "Bom";
  };

  return {
    gorduraCorporal: avaliar(
      bioimpedancia.gorduracorporal,
      faixa.gorduraCorporal
    ),
    imc: avaliar(bioimpedancia.imc, faixa.imc),
    gorduraVisceral: avaliar(
      bioimpedancia.gorduravisceral,
      faixa.gorduraVisceral
    ),
    massaMuscular: avaliar(bioimpedancia.massamuscular, faixa.massaMuscular),
    massaOssea: avaliar(bioimpedancia.massaossea, faixa.massaOssea),
    aguaCorporal: avaliar(bioimpedancia.aguacorporal, faixa.aguaCorporal),
  };
};

export default function Pesquisa() {
  const [socioDemograficoData, setSocioDemograficoData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [habitosDeVidaData, setHabitosDeVidaData] = useState([]);
  const [esgotamentoProfissionalData, setEsgotamentoProfissionalData] =
    useState([]);
  const [bioimpedanciaData, setBioimpedanciaData] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: socioDemografico, error: socioDemograficoError } =
          await supabase.from("sociodemograficoEscolar").select("*");

        if (socioDemograficoError) throw socioDemograficoError;
        setSocioDemograficoData(socioDemografico);
        setFilteredData(socioDemografico);

        const { data: habitosDeVida, error: habitosDeVidaError } =
          await supabase
            .from("habitosVida")
            .select("*")
            .in(
              "id",
              socioDemografico.map((item) => item.id)
            );

        if (habitosDeVidaError) throw habitosDeVidaError;
        setHabitosDeVidaData(habitosDeVida);

        const {
          data: esgotamentoProfissional,
          error: esgotamentoProfissionalError,
        } = await supabase
          .from("avaliEsgoPro")
          .select("*")
          .in(
            "id",
            socioDemografico.map((item) => item.id)
          );

        if (esgotamentoProfissionalError) throw esgotamentoProfissionalError;
        setEsgotamentoProfissionalData(esgotamentoProfissional);

        const { data: bioimpedancia, error: bioimpedanciaError } =
          await supabase
            .from("bioimpedancia")
            .select("*")
            .in(
              "id",
              socioDemografico.map((item) => item.id)
            );

        if (bioimpedanciaError) throw bioimpedanciaError;
        setBioimpedanciaData(bioimpedancia);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedSchool) {
      const filtered = socioDemograficoData.filter(
        (item) => item.escola === selectedSchool
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(socioDemograficoData);
    }
  }, [selectedSchool, socioDemograficoData]);

  const schoolOptions = [
    ...new Set(socioDemograficoData.map((item) => item.escola)),
  ].map((escola) => ({ value: escola, label: escola }));

  function mascararCPF(cpf) {
    if (!cpf || cpf.length !== 14) return cpf;
    return `XXX.${cpf.slice(4, 7)}.${cpf.slice(8, 11)}.${cpf.slice(12, 13)}X`;
  }

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <DivButton>
            <ButtonReturn onClick={handleProfileClick} />
          </DivButton>
          <FormHeader>
            <h2 style={{ textTransform: "uppercase" }}>
              Lista de participantes da Pesquisa
            </h2>
          </FormHeader>

          <FormGroup>
            <FormLabel>Escolha uma escola</FormLabel>
            <FormSelect
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              options={[...schoolOptions]}
              customWidth="25vw"
            />
          </FormGroup>

          <ScrollableContainer>
            <Table>
              <thead>
                <tr>
                  <TableTH>Nome</TableTH>
                  <TableTH>CPF</TableTH>
                  <TableTH>Email</TableTH>
                  <TableTH>Gênero</TableTH>
                  <TableTH>Faixa Etária</TableTH>
                  <TableTH>Raça</TableTH>
                  <TableTH>Estado Civil</TableTH>
                  <TableTH>Nº de Filhos</TableTH>
                  <TableTH>Escolaridade</TableTH>

                  <TableTH>Carga Horária</TableTH>

                  <TableTH>Cargo</TableTH>
                  <TableTH>Turno</TableTH>
                  <TableTH>Anos na Empresa</TableTH>
                  <TableTH>Dias de Trabalho</TableTH>
                  <TableTH>Renda Familiar</TableTH>
                  <TableTH>1 atividade remunerada</TableTH>
                  <TableTH>Nº Atividades</TableTH>
                  <TableTH>Ausências no Ano</TableTH>
                  <TableTH>Disciplina ministrada</TableTH>
                  <TableTH>Tipo de contrato</TableTH>
                  <TableTH>Motivos de Afastamento</TableTH>
                  <TableTH>Escola</TableTH>
                  <TableTH>Ambiente</TableTH>
                  <TableTH>Relações Sociais</TableTH>
                  <TableTH>Psicológico</TableTH>
                  <TableTH>Físico</TableTH>
                  <TableTH>Classificação de Burnout</TableTH>

                  <TableTH>Gordura Corporal</TableTH>
                  <TableTH>IMC</TableTH>
                  <TableTH>Gordura Visceral</TableTH>
                  <TableTH>Massa Muscular</TableTH>
                  <TableTH>Massa Óssea</TableTH>
                  <TableTH>Água Corporal</TableTH>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item) => {
                  const bioimpedancia = bioimpedanciaData.find(
                    (bio) => bio.id === item.id
                  );
                  const resultados =
                    bioimpedancia &&
                    avaliarBioimpedancia(
                      bioimpedancia,
                      item.gender,
                      item.ageRange
                    );
                  return (
                    <tr key={item.id}>
                      <TableTD>{item.name}</TableTD>
                      <TableTD>{mascararCPF(item.id)}</TableTD>
                      <TableTD>{item.email || "----"}</TableTD>
                      <TableTD>{item.gender}</TableTD>
                      <TableTD>{item.ageRange}</TableTD>
                      <TableTD>{item.race}</TableTD>
                      <TableTD>{item.maritalStatus}</TableTD>
                      <TableTD>{item.numberOfChildren}</TableTD>
                      <TableTD>{item.educationLevel}</TableTD>
                      <TableTD>{item.workHours}</TableTD>
                      <TableTD>{item.role}</TableTD>
                      <TableTD>{item.shift}</TableTD>
                      <TableTD>{item.yearsInCorporation}</TableTD>
                      <TableTD>{item.workDays}</TableTD>
                      <TableTD>{item.rendaFamiliar}</TableTD>
                      <TableTD>
                        {item.otherPaidActivity ? "Sim" : "Não"}
                      </TableTD>
                      <TableTD>{item.numberOfOtherPaidActivities}</TableTD>
                      <TableTD>{item.absencesLastYear}</TableTD>
                      <TableTD>{item.q10}</TableTD>
                      <TableTD>{item.q11}</TableTD>
                      <TableTD>
                        {(() => {
                          try {
                            const parsedData = JSON.parse(
                              item.afastamentoCausas
                            );
                            if (Array.isArray(parsedData)) {
                              return parsedData.join(", ");
                            }
                            if (typeof parsedData === "object") {
                              return Object.entries(parsedData).map(
                                ([key, value]) => (
                                  <div key={key}>
                                    <strong>{key}:</strong> {value}
                                  </div>
                                )
                              );
                            }
                            return parsedData;
                          } catch (error) {
                            return item.afastamentoCausas;
                          }
                        })()}
                      </TableTD>
                      <TableTD>{item.escola}</TableTD>
                      <>
                        {(() => {
                          const habitos = habitosDeVidaData.find(
                            (habito) => habito.id === item.id
                          );
                          if (habitos) {
                            return (
                              <>
                                <TableTD>
                                  {habitos.environmentDomainClassification}
                                </TableTD>
                                <TableTD>
                                  {habitos.socialRelationsDomainClassification}
                                </TableTD>
                                <TableTD>
                                  {habitos.psychologicalDomainClassification}
                                </TableTD>
                                <TableTD>
                                  {habitos.physicalDomainClassification}
                                </TableTD>
                              </>
                            );
                          }
                          return (
                            <>
                              <TableTD>----</TableTD>
                              <TableTD>----</TableTD>
                              <TableTD>----</TableTD>
                              <TableTD>----</TableTD>
                            </>
                          );
                        })()}
                      </>
                      <TableTD>
                        {(() => {
                          const esgotamento = esgotamentoProfissionalData.find(
                            (esgo) => esgo.id === item.id
                          );
                          if (esgotamento) {
                            return esgotamento.burnoutClassification;
                          }
                          return "----";
                        })()}
                      </TableTD>
                      {resultados ? (
                        <>
                          <TableTD>{resultados.gorduraCorporal}</TableTD>
                          <TableTD>{resultados.imc}</TableTD>
                          <TableTD>{resultados.gorduraVisceral}</TableTD>
                          <TableTD>{resultados.massaMuscular}</TableTD>
                          <TableTD>{resultados.massaOssea}</TableTD>
                          <TableTD>{resultados.aguaCorporal}</TableTD>
                        </>
                      ) : (
                        <>
                          <TableTD>----</TableTD>
                          <TableTD>----</TableTD>
                          <TableTD>----</TableTD>
                          <TableTD>----</TableTD>
                          <TableTD>----</TableTD>
                          <TableTD>----</TableTD>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </ScrollableContainer>
        </FormContainer>
      </Container>
    </>
  );
}
