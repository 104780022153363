import React, { useEffect, useState } from "react";
import supabase from "../../../../Supabase";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import {
  FormContainer,
  ChartContainer,
  ResultTitle,
  ToggleButton,
  DivButton,
  ContainerFilter,
  TitleFilter,
} from "./styles";
import HeaderGeral from "../../../../Components/headerGeral";

import ButtonReturn from "../../../../Components/button-return";
import { Container } from "../../../../Components/styles-all";

import {
  questionsAndResponses,
  questionsAndResponsesForm2,
  categoryTitles,
  questionsAndResponsesForm21,
  questionsAndResponsesForm21Titles,
  questionsAndResponsesForm13,
  questionsAndResponsesForm13Titles,
} from "./titles";
import ResultadoBIA from "./results-bia";
import FormGroup from "../../../../Components/form-group";
import FormSelect from "../../../../Components/form-select";
Chart.register(...registerables);
Chart.register(ChartDataLabels);

const chartOptions = {
  indexAxis: "y",
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          const totalResponses = dataset.totalResponses || 0;
          const count = Math.round((value / 100) * totalResponses);
          return `Quantidade: ${count} (${value.toFixed(2)}%)`;
        },
      },
    },
    datalabels: {
      color: "#111",
      display: function (context) {
        return context.dataset.data[context.dataIndex] !== 0;
      },
      font: {
        weight: "bold",
      },
      formatter: function (value, context) {
        return `${value.toFixed(2).toLocaleString("pt-BR")}%`;
      },
      padding: 10,
    },
  },
  scales: {
    y: {
      ticks: {
        padding: 20,
      },
    },
    x: {
      ticks: {
        beginAtZero: true,
        stepSize: 20,
        callback: function (value) {
          return `${value}%`;
        },
      },
    },
  },
};

const ResultadoPILOTO = () => {
  const [showAll, setShowAll] = useState(false);
  const [showAll3, setShowAll3] = useState(false);
  const [resultsForm3, setResultsForm3] = useState([]);
  const [resultsForm2, setResultsForm2] = useState([]);
  const [resultsForm21, setResultsForm21] = useState([]);
  const [resultsForm13, setResultsForm13] = useState([]);
  const [activeChart, setActiveChart] = useState(null);

  const [selectedSchool, setSelectedSchool] = useState("");
  const [schoolOptions, setSchoolOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let form2Query = supabase.from("sociodemograficoEscolar").select("*");

      if (selectedSchool) {
    
        form2Query = form2Query.eq("escola", selectedSchool);
      }

      const { data: form2Data, error: form2Error } = await form2Query;

      if (form2Error) {
        console.error("Erro ao buscar dados do Form2:", form2Error);
        return;
      }

      const form3Ids = form2Data.map((item) => item.id);

      const { data: form3Data, error: form3Error } = await supabase
        .from("caracHabVida")
        .select("*")
        .in("id", form3Ids);

      const { data: form21Data, error: form21Error } = await supabase
        .from("habitosVida")
        .select("*")
        .in("id", form3Ids);

      const { data: form13Data, error: form13Error } = await supabase
        .from("avaliEsgoPro")
        .select("*")
        .in("id", form3Ids);

      // Atualiza os estados com os dados obtidos
      if (!form3Error) setResultsForm3(form3Data);
      if (!form2Error) setResultsForm2(form2Data);
      if (!form21Error) setResultsForm21(form21Data);
      if (!form13Error) setResultsForm13(form13Data);
    }

    fetchData();
  }, [selectedSchool]);

  const colors = [
    "rgba(255, 182, 193, 1)",
    "rgba(173, 216, 230, 1)",
    "rgba(255, 255, 179, 1)",
    "rgba(144, 238, 144, 1)",
    "rgba(216, 191, 216, 1)",
    "rgba(176, 224, 230, 1)",
    "rgba(255, 228, 181, 1)",
    "rgba(144, 238, 144, 1)",
    "rgba(255, 160, 122, 1)",
    "rgba(221, 160, 221, 1)",
    "rgba(216, 191, 216, 1)",
    "rgba(152, 251, 152, 1)",
    "rgba(176, 224, 230, 1)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 255, 127, 1)",
    "rgba(102, 205, 170, 1)",
    "rgba(255, 222, 173, 1)",
    "rgba(189, 183, 107, 1)",
  ];

  const responseCounts = {};

  for (let question in questionsAndResponses) {
    responseCounts[question] = Array(
      questionsAndResponses[question].length
    ).fill(0);
  }

  resultsForm3.forEach((entry) => {
    for (let question in questionsAndResponses) {
      const responseIndex = questionsAndResponses[question].indexOf(
        entry[question]
      );
      if (responseIndex !== -1) {
        responseCounts[question][responseIndex]++;
      }
    }
  });

  const responsePercentages = {};
  for (let key in responseCounts) {
    const totalResponses = resultsForm3.length;
    responsePercentages[key] = responseCounts[key].map((count) => {
      const percentage = (count / totalResponses) * 100;
      return Math.min(100, Math.max(0, percentage));
    });
  }

  const calculateResponsePercentages = (data, questionsAndResponses) => {
    const responseCounts = {};

    // Inicializa o contador de respostas para cada pergunta
    for (let question in questionsAndResponses) {
      responseCounts[question] = Array(
        questionsAndResponses[question].length
      ).fill(0);
    }

    data.forEach((entry) => {
      for (let question in questionsAndResponses) {
        if (question === "afastamentoCausas") {
          if (typeof entry[question] === "string") {
            const parsedAfastamentoCausas = JSON.parse(entry[question]);

            // Se o array for vazio, conta como "Nenhuma vez"
            if (parsedAfastamentoCausas.length === 0) {
              const responseIndex =
                questionsAndResponses[question].indexOf("Nenhuma vez");
              if (responseIndex !== -1) {
                responseCounts[question][responseIndex]++;
              }
            } else {
              // Caso contrário, trata as respostas normais
              parsedAfastamentoCausas.forEach((response) => {
                // Substitui a string vazia por "Nenhuma vez" durante o cálculo
                if (response === "") {
                  response = "Nenhuma vez";
                }
                const responseIndex =
                  questionsAndResponses[question].indexOf(response);
                if (responseIndex !== -1) {
                  responseCounts[question][responseIndex]++;
                }
              });
            }
          } else {
            // Para os casos em que o valor não é uma string (array já fornecido, por exemplo)
            const responseIndex = questionsAndResponses[question].indexOf(
              entry[question]
            );
            if (responseIndex !== -1) {
              responseCounts[question][responseIndex]++;
            }
          }
        } else {
          // Para as outras questões, apenas conta as respostas diretamente
          const responseIndex = questionsAndResponses[question].indexOf(
            entry[question]
          );
          if (responseIndex !== -1) {
            responseCounts[question][responseIndex]++;
          }
        }
      }
    });

    const responsePercentages = {};
    for (let key in responseCounts) {
      const totalResponses = data.length;
      responsePercentages[key] = responseCounts[key].map(
        (count) => (count / totalResponses) * 100
      );
    }

    return responsePercentages;
  };

  const form2ResponsePercentages = calculateResponsePercentages(
    resultsForm2,
    questionsAndResponsesForm2
  );

  const form21ResponsePercentages = calculateResponsePercentages(
    resultsForm21,
    questionsAndResponsesForm21
  );

  const form13ResponsePercentages = calculateResponsePercentages(
    resultsForm13,
    questionsAndResponsesForm13
  );

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };
  useEffect(() => {
    async function fetchSchools() {
      const { data: schoolData, error } = await supabase
        .from("sociodemograficoEscolar")
        .select("escola");
      if (error) {
        console.error("Erro ao buscar escolas:", error);
      } else {
        const uniqueSchools = [
          ...new Set(schoolData.map((item) => item.escola)),
        ];
        setSchoolOptions(uniqueSchools);
      }
    }

    fetchSchools();
  }, []);

  return (
    <>
      <HeaderGeral />
      <Container>
        <DivButton>
          <ButtonReturn onClick={handleProfileClick} />
        </DivButton>

        <ContainerFilter>
          <TitleFilter>Faça um filtro por escola!</TitleFilter>

          <FormSelect
            value={selectedSchool}
            onChange={(e) => setSelectedSchool(e.target.value)}
            options={[...schoolOptions]}
            customWidth="25vw"
          />
        </ContainerFilter>

        <ResultTitle>sociodemográfico</ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm2).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${categoryTitles[question]}`
                  : `Mostrar ${categoryTitles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>{categoryTitles[question]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm2[question],
                      datasets: [
                        {
                          label: "ocultar",
                          data: form2ResponsePercentages[question],
                          backgroundColor: colors,
                          borderWidth: 1,
                          totalResponses: resultsForm2.length,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm2).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>{categoryTitles[question]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm2[question],
                      datasets: [
                        {
                          label: "ocultar",
                          data: form2ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                          totalResponses: resultsForm2.length,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>
          AVALIAÇÃO DA QUALIDADE DE VIDA RELACIONADA A SAÚDE
        </ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm21).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm21Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm21Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm21Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm21[question],
                      datasets: [
                        {
                          label: "ocultar",
                          data: form21ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                          totalResponses: resultsForm21.length,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll3(!showAll3)}>
          {showAll3 ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll3 &&
            Object.keys(questionsAndResponsesForm21).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm21Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm21[question],
                      datasets: [
                        {
                          label: "ocultar",
                          data: form21ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                          totalResponses: resultsForm21.length,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm13).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm13Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm13Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm13Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm13[question],
                      datasets: [
                        {
                          label: "ocultar",
                          data: form13ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                          totalResponses: resultsForm13.length,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultadoBIA escola={selectedSchool} />
      </Container>
    </>
  );
};

export default ResultadoPILOTO;
