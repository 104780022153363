import React, { useState, useEffect } from "react";
import styled from "styled-components";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";
import { File } from "@phosphor-icons/react";

import {
  Container,
  FormContainer,
  FormHeader,
} from "../../../Components/styles-all";

import HeaderGeral from "../../../Components/headerGeral";

import ButtonReturn from "../../../Components/button-return";

const Button = styled.button`
  display: flex;
  flex-direction: row;
  background-color: ${({ clicked }) => (clicked ? "black" : "#2e19bd")};
  color: ${({ clicked }) => (clicked ? "white" : "white")};
  border-radius: 5px;
  border: none;
  min-width: 10.12vw;
  height: 30px;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  &:hover {
    transform: scale(1.09);
  }

  @media (max-width: 768px) {
    width: 90vw;
    margin-left: 2vw;
    margin-bottom: 50px;
    height: 5vh;
    &:hover {
      transform: scale(1);
    }
  }
`;

const PaperItem = styled.div`
  border: 1px solid #111;
  height: auto;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-top: 10px;
`;

const Title = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 12px;
  text-align: justify;
`;

const Authors = styled.p`
  font-size: 1rem;
  color: #111;
  margin-bottom: 8px;
`;

const Summary = styled.p`
  font-size: 1rem;
  margin-bottom: 8px;
  text-align: justify;
`;

const Keywords = styled.p`
  font-size: 1rem;
  color: #777;
  margin-bottom: 10px;
`;

const EscreverPaper = () => {
  const navigate = useNavigate();
  const [dados, setDados] = useState([]);

  const consultarDados = async () => {
    try {
      const { data, error } = await supabase.from("paper").select("*");

      if (error) {
      } else {
        setDados(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    consultarDados();
  }, []);

  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>PAPER</h2>
          </FormHeader>
          <ButtonReturn onClick={handleProfileClick} />

          {dados.map((item) => (
            <PaperItem key={item.id}>
              <Title>{item.title}</Title>
              <Authors>Autores: {item.authors}</Authors>
              <Summary>Resumo: {item.summary}</Summary>

              {item.Keywords && (
                <Keywords>Palavras-chave: {item.Keywords}</Keywords>
              )}

              <a
                href={decodeURIComponent(
                  JSON.parse(item.file_url).data.publicUrl
                )}
                download
              >
                <Button>
                  <File size={22} />
                  Acessar Arquivo
                </Button>
              </a>
            </PaperItem>
          ))}
        </FormContainer>
      </Container>
    </>
  );
};

export default EscreverPaper;
